const FORM_OPERATION_BODY = `
name
title
schema
options
model
valid
validation_messages
metadata`;

export default FORM_OPERATION_BODY;
